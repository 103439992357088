$('#offcanvas-menu-toggle').click(function(){
    $('body').toggleClass('offcanvas-menu-active');
    $(this).toggleClass('active');
});

$('.toggle-search').click(function(){
    $('#offcanvas-search').toggleClass('active');
    
    if($('#offcanvas-search').hasClass('active')){
        setTimeout(function() { 
            $('#offcanvas-search .searchbox input')[0].focus(); 
        }, 300);
    }

    $(this).find('.fa').toggleClass('fa-search fa-close');
});

function loadArticles(name, value){
    var url = window.location.href;
    if(name != null){
        url += '?'+name+'='+value;
    }
    $.ajax({
        url: url, 
        cache: false,
        success: function(response) {
            result = $(response).find('#filterUpdate').html();
            $('#filterUpdate').html(result);
            console.log(result);
        }
    });
}
$('body').on('click', '.filter .filter-option', function() {
    loadArticles($(this).data('name'), $(this).data('value'), false);
    $(this).prependTo($(this).closest('ul'));
});

$('.dropdown-text .headline').click(function(){
   $(this).closest('.dropdown-text').find('.text').slideToggle(); 
});

// ==== Ajax Searcher ===== //
$('.searchbox input').keyup(function(){
    $('.searchbox').find('.results').removeClass('hidden');
	$.ajax({
		url: '/?altTemplate=Search&s='+$(this).val()
	}).done(function(data){
		$('.searchbox').find('.results').html(data);
	});
});


$('.js-cookie-button').click(function(){
    $('#cookie').fadeOut();
    sessionStorage.setItem('cookie', 'closed');
});

function checkScrollPosition() {
    if($('#progress').length){
        var s = $(window).scrollTop(),
        d = $(document).height(),
        c = $(window).height();
        var scrollPercent = (s / (d - c)) * 100;

        $('#progress').css('width', scrollPercent + '%')
    }
}


$(window).on('scroll', function(){
    checkScrollPosition();
});

$(function() {
    checkScrollPosition();
    
    if(sessionStorage.getItem('cookie') != 'closed') {
        $('#cookie').fadeIn();
    }

    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                return false;
            }
        }
    });
});